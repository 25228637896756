import { useState } from "react";
import { Card, ButtonGroup, ToggleButton, Button } from "react-bootstrap";
import { Chart, RadialLinearScale, ArcElement, Tooltip, Legend } from "chart.js";
import EqualFactor from "../components/EqualFactor";
import EqualCategory from "../components/EqualCategory";

Chart.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const Result = ({ resultData, onBack }) => {
  const [type, setType] = useState("equalCategory");

  return (
    <Card>
      <Card.Body>
        <h1>Result</h1>
        <Button variant="primary" className="mb-2" onClick={onBack}>
          Back
        </Button>
        <br />
        <ButtonGroup>
          <ToggleButton
            id="1"
            type="radio"
            variant="outline-success"
            name="equalFactor"
            value="equalFactor"
            checked={type === "equalFactor"}
            onChange={(e) => setType(e.currentTarget.value)}
          >
            Equal Factor
          </ToggleButton>
          <ToggleButton
            id="2"
            type="radio"
            variant="outline-success"
            name="equalCategory"
            value="equalCategory"
            checked={type === "equalCategory"}
            onChange={(e) => setType(e.currentTarget.value)}
          >
            Equal Category
          </ToggleButton>
        </ButtonGroup>
        {type === "equalFactor" ? <EqualFactor resultData={resultData} /> : <EqualCategory resultData={resultData} />}
      </Card.Body>
    </Card>
  );
};

export default Result;
