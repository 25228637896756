import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useState, useEffect } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { data } from "./data.js";
import Result from "./pages/Result.js";
import ActorButton from "./components/ActorButton.js";
import bg1 from "./assets/bg1.png";
import bg2 from "./assets/bg2.png";
import bg3 from "./assets/bg3.png";

function App() {
  const [actor, setActor] = useState("");
  const [showResults, setShowResults] = useState(false);
  const { register, watch, getValues, setValue } = useForm();

  useEffect(() => {
    const subscription = watch(() => console.log(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  const formatData = () => {
    const formattedData = data.reduce(
      (acc, category, catIndex) => [
        ...acc,
        ...category.subsections.reduce(
          (acc2, subsection, subIndex) => [
            ...acc2,
            ...subsection.factors.map((factor, factorIndex) => ({
              category: category.title,
              subsection: subsection.title,
              factor,
              value: getValues(`${catIndex}.${subIndex}.${factorIndex}`),
            })),
          ],
          []
        ),
      ],
      []
    );
    return formattedData.map((item) => ({ ...item, value: item.value === "na" ? 0 : item.value === "low" ? 1 : item.value === "medium" ? 2 : 3 }));
  };

  const setValues = () => {
    setValue("0.0.0", "low");
    setValue("0.0.1", "medium");
    setValue("0.0.2", "high");
    setValue("0.1.0", "low");
    setValue("0.1.1", "medium");
    setValue("0.1.2", "high");
    setValue("1.0.0", "low");
    setValue("1.0.1", "medium");
    setValue("1.1.0", "high");
    setValue("1.1.1", "low");
    setValue("2.0.0", "medium");
    setValue("2.0.1", "high");
    setValue("2.0.2", "low");
    setValue("2.0.3", "medium");
    setValue("2.0.4", "high");
    setValue("2.1.0", "low");
    setValue("2.1.1", "medium");
    setValue("3.0.0", "high");
    setValue("3.0.1", "low");
    setValue("3.0.2", "medium");
    setValue("3.1.0", "high");
    setValue("3.1.1", "low");
    setValue("3.1.2", "medium");
    setValue("4.0.0", "high");
    setValue("4.0.1", "low");
    setValue("4.0.2", "medium");
    setValue("4.1.0", "high");
    setValue("4.1.1", "low");
  };

  return (
    <div style={{ backgroundColor: "#EDF2F9" }}>
      <div className="container p-4 min-vh-100">
        {!actor ? (
          <div>
            <div className="mb-5 text-center">
              <h1 className="m-0" style={{ color: "rgb(44, 123, 229)", fontFamily: "sans-serif", fontWeight: 800 }}>
                CMAA
              </h1>
              <div style={{ fontWeight: 200, color: "rgb(91, 110, 130)" }}>Cloud Manufacturing Adoption Assessment</div>
            </div>
            <div className="d-none d-md-block">
              <Row className="justify-content-center">
                <Col xs="3" xl="2" className="">
                  <ActorButton title="Platform Provider" onSelectActor={setActor} image={bg1} />
                </Col>
                <Col xs="3" xl="2">
                  <ActorButton title="Resource Provider" onSelectActor={setActor} image={bg2} />
                </Col>
                <Col xs="3" xl="2">
                  <ActorButton title="User" onSelectActor={setActor} image={bg3} />
                </Col>
              </Row>
            </div>
            <div className="d-block d-md-none">
              <Row className="justify-content-center mb-3">
                <Col xs="8" sm="6" className="">
                  <ActorButton title="Platform Provider" onSelectActor={setActor} image={bg1} />
                </Col>
              </Row>
              <Row className="justify-content-center mb-3">
                <Col xs="8" sm="6">
                  <ActorButton title="Resource Provider" onSelectActor={setActor} image={bg2} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="8" sm="6">
                  <ActorButton title="User" onSelectActor={setActor} image={bg3} />
                </Col>
              </Row>
            </div>
          </div>
        ) : !showResults ? (
          <>
            <div className="mb-3 ms-4">
              <h1 className="m-0" style={{ color: "rgb(44, 123, 229)", fontFamily: "sans-serif", fontWeight: 800 }}>
                CMAA
              </h1>
              <div onClick={() => setValues()} style={{ fontWeight: 200, color: "rgb(91, 110, 130)" }}>
                Cloud Manufacturing Adoption Assessment
              </div>
            </div>
            <Card
              className="mb-3"
              style={{
                backgroundColor: "#FFFFFF",
                borderColor: "rgba(0, 0, 0, 0.125)",
                borderWidth: 0,
                boxShadow: "rgba(65, 69, 88, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px",
                color: "rgb(94, 110, 130)",
              }}
            >
              <Card.Body
                className="py-3 d-flex flex-wrap flex-between-center"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderBottomColor: "rgba(0, 0, 0, 0.125)",
                  borderBottomWidth: "0px",
                  borderTopRightRadius: "6px",
                  borderTopLeftRadius: "6px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="d-flex flex-wrap align-items-center">
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => setActor("")}
                    style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      borderColor: "rgb(255, 255, 255)",
                      boxShadow:
                        "rgba(43, 45, 80, 0.1) 0px 0px 0px 1px, rgba(43, 45, 80, 0.08) 0px 2px 5px 0px, rgba(0, 0, 0, 0.07) 0px 1px 1.5px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px",
                      color: "rgb(77, 89, 105)",
                      fontWeight: 500,
                    }}
                  >
                    {"‹"}
                  </Button>
                  <Card.Title as="h4" className="m-0 ms-3">
                    {`${actor} Role`}
                  </Card.Title>
                </div>
                <div>
                  <Button
                    variant="success"
                    size="sm"
                    className="mx-2"
                    style={{
                      backgroundColor: "rgb(0, 179, 104)",
                      borderColor: "rgb(0, 168, 98)",
                      boxShadow: "rgba(255, 255, 255, 0.15) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0,075) 0px 1px 1px 0px",
                      fontWeight: 500,
                    }}
                    onClick={() => setShowResults(true)}
                  >
                    Submit
                  </Button>
                </div>
              </Card.Body>
            </Card>
            {data.map((category, categoryIndex) => (
              <Card
                key={categoryIndex}
                className="mb-3"
                style={{
                  backgroundColor: "#FFFFFF",
                  borderColor: "rgba(0, 0, 0, 0.125)",
                  borderWidth: 0,
                  boxShadow: "rgba(65, 69, 88, 0.1) 0px 7px 14px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px",
                  color: "rgb(94, 110, 130)",
                }}
              >
                <Card.Header
                  className="py-3"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderBottomColor: "rgba(0, 0, 0, 0.125)",
                    borderBottomWidth: "0px",
                    borderTopRightRadius: "6px",
                    borderTopLeftRadius: "6px",
                  }}
                >
                  <Card.Title as="h3" className="m-0">
                    {category.title}
                  </Card.Title>
                </Card.Header>
                <Card.Body
                  style={{
                    backgroundColor: "rgb(249, 250, 253)",
                    borderBottomRightRadius: "6px",
                    borderBottomLeftRadius: "6px",
                  }}
                >
                  {category.subsections?.map((subsection, subsectionIndex, subsections) => (
                    <Fragment key={subsectionIndex}>
                      <Card.Title className="mb-3">{subsection.title}</Card.Title>
                      {subsection.factors?.map((factor, factorIndex) => (
                        <div key={factorIndex} className="mx-3">
                          <Card.Text className="mb-1">{`${factor}:`}</Card.Text>
                          <Form.Group as={Row} className="mb-3">
                            <Col xs="12" sm="6" md="3">
                              <Form.Check
                                type="radio"
                                label="Low"
                                value="low"
                                id={`${categoryIndex}.${subsectionIndex}.${factorIndex}.Low`}
                                {...register(`${categoryIndex}.${subsectionIndex}.${factorIndex}`)}
                              />
                            </Col>
                            <Col xs="12" sm="6" md="3">
                              <Form.Check
                                type="radio"
                                label="Medium"
                                value="medium"
                                id={`${categoryIndex}.${subsectionIndex}.${factorIndex}.Medium`}
                                {...register(`${categoryIndex}.${subsectionIndex}.${factorIndex}`)}
                              />
                            </Col>
                            <Col xs="12" sm="6" md="3">
                              <Form.Check
                                type="radio"
                                label="High"
                                value="high"
                                id={`${categoryIndex}.${subsectionIndex}.${factorIndex}.High`}
                                {...register(`${categoryIndex}.${subsectionIndex}.${factorIndex}`)}
                              />
                            </Col>
                            <Col xs="12" sm="6" md="3">
                              <Form.Check
                                type="radio"
                                label="Not Applicable"
                                value="na"
                                id={`${categoryIndex}.${subsectionIndex}.${factorIndex}.NA`}
                                {...register(`${categoryIndex}.${subsectionIndex}.${factorIndex}`)}
                              />
                            </Col>
                          </Form.Group>
                        </div>
                      ))}
                      {subsectionIndex < subsections.length - 1 && <hr />}
                    </Fragment>
                  ))}
                </Card.Body>
              </Card>
            ))}
          </>
        ) : (
          <Result resultData={formatData()} onBack={() => setShowResults(false)} />
        )}
      </div>
    </div>
  );
}

export default App;
