import { PolarArea } from "react-chartjs-2";

const EqualFactor = ({ resultData }) => {
  const filteredData = resultData.filter((result) => result.value !== 0);
  const colors = ["#848482", "#CB4154", "#FFAA1D", "#7BB661"];
  const data = {
    labels: filteredData.map((result) => result.factor),
    datasets: [
      {
        // label: "My First Dataset",
        data: filteredData.map((result) => result.value),
        backgroundColor: filteredData.map((result) => colors[result.value]),
      },
    ],
  };
  const ticks = (value) => {
    switch (value) {
      case 1:
        return "Low";
      case 2:
        return "Medium";
      case 3:
        return "High";
      default:
        return value;
    }
  };

  const labels = (_value, index) => {
    const currentCategoryIndices = filteredData
      .map((result, i) => (result.category === filteredData[index].category ? i : null))
      .filter((index) => index !== null);
    const currentCategoryIndicesMiddlePoint = currentCategoryIndices[Math.ceil(currentCategoryIndices.length / 2) - 1];
    return index === currentCategoryIndicesMiddlePoint ? filteredData[index].category : null;
  };

  const angleLines = {
    id: "angleLines",
    afterDatasetsDraw(chart) {
      const {
        ctx,
        scales: { r },
      } = chart;
      const xCenter = r.xCenter;
      const yCenter = r.yCenter;
      const drawingArea = r.drawingArea;
      chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
        if (index === 0 || filteredData[index].category !== filteredData[index - 1].category) {
          ctx.save();
          ctx.translate(xCenter, yCenter);
          ctx.rotate(dataPoint.startAngle + Math.PI / 2);
          ctx.beginPath();
          ctx.strokeStytle = "black";
          ctx.lineWidth = 3;
          ctx.moveTo(0, 0);
          ctx.lineTo(0, -drawingArea);
          ctx.stroke();
          ctx.restore();
        }
      });
    },
  };

  const options = {
    scales: {
      r: {
        min: 0,
        max: 3,
        ticks: {
          stepSize: 1,
          z: 1,
          callback: ticks,
        },
        grid: {
          z: 1,
        },
        pointLabels: {
          display: [true, false, false, false],
          centerPointLabels: true,
          font: {
            size: 5,
          },
          callback: labels,
        },
      },
    },
    // elements: {
    //   arc: {
    //     angle: [1, 3, 5, 7, 1, 3, 5, 7, 1, 3, 5, 7, 1, 3, 5, 7, 1, 3, 5, 7, 1, 3, 5, 7, 1, 3, 5, 7],
    //   },
    // },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <PolarArea data={data} options={options} plugins={[angleLines]} />;
};

export default EqualFactor;
