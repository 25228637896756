export const data = [
  {
    title: "Organization",
    subsections: [
      {
        title: "Characteristics",
        factors: ["Organization Size", "Innovation Atmosphere", "Previous Experience"],
      },
      {
        title: "Management",
        factors: ["Strategic Planning", "Top Management Support", "Buyer-Supplier Relationship"],
      },
    ],
  },
  {
    title: "Business",
    subsections: [
      {
        title: "Marketing",
        factors: ["Competitive Advantage", "Brand Image"],
      },
      {
        title: "Financial",
        factors: ["Cost-Benefit Analysis", "Demand Analysis"],
      },
    ],
  },
  {
    title: "Human",
    subsections: [
      {
        title: "Mindset",
        factors: ["Perceived Usefulness", "Perceived Risk", "Perceived Ease of Use", "Attitude Toward Sharing", "Attitude Toward Change"],
      },
      {
        title: "Competency",
        factors: ["Digital Skills", "Training"],
      },
    ],
  },
  {
    title: "Technology",
    subsections: [
      {
        title: "Maintainability",
        factors: ["Compatibility", "IT Support", "Security and Data Protection"],
      },
      {
        title: "Establishment",
        factors: ["Complexity and Architecture", "Resource Readiness", "Cloud Readiness"],
      },
    ],
  },
  {
    title: "Environment",
    subsections: [
      {
        title: "Industry",
        factors: ["Market Pressure", "Market Uncertainty", "Consumer Readiness"],
      },
      {
        title: "Legislation",
        factors: ["Government Regulations", "Clear Cloud Usage Policy"],
      },
    ],
  },
];
